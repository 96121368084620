<template>
  <div id="pano"></div>
</template>

<script setup>
onMounted(() => {
  const embedpano = window.embedpano;
  embedpano({
    id: "inner",
    xml: "/krpano/showroom.xml",
    target: "pano",
    consolelog: false,
    html5: "only",
    onready: (pano) => {
      window.pano = pano;
    },
  });
});
</script>

<style lang="scss">
#pano {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (max-width: 767px) {
  #pano {
    /* mobile viewport bug fix */
    height: -webkit-fill-available;
  }
}
</style>
